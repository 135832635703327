/*** React Select ***/

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../core/variables/components-variables";

.select__control,
.React__control {
  &.select__control--is-focused,
  &.React__control--is-focused {
    border-color: $primary !important;
    box-shadow: $input-focus-box-shadow;
  }
  &.select__control--menu-is-open,
  &.React__control--is-focused {
    border-color: $input-focus-border-color !important;
    box-shadow: $input-focus-box-shadow;
  }
}
.select__menu,
.React__menu {
  .select__menu-list,
  .React__menu-list {
    .select__option,
    .React__option {
      &:hover {
        background-color: lighten($color: $primary, $amount: 10%);
        color: $white;
      }
      &.select__option--is-selected,
      &.React__option--is-selected {
        background-color: $primary;
      }
    }
  }
}

.is-invalid {
  .select__control {
    border-color: $danger !important;
  }
}