// ================================================================================================
//   File Name: chat-application.scss
//   Description: SCC file for chat application page.
//   ----------------------------------------------------------------------------------------------
//   Item Name: Vuexy React Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Load variable overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

$chat-image-back-color: #dfdbe5;

.author-feedback-page {
  height: 100vh;
  background: url("../../img/pages/author-feedback/author-feeedback.svg") center 5vh no-repeat, #e6eaf5;
  background-size: cover;
  width: 100%;
  position: relative;

  @media (max-aspect-ratio: 2880/1800) {
    background-size: contain;
    background-position: bottom center;
  }

  @include media-breakpoint-down(sm) {
    background: #fff;
  }

  .container {
    max-width: 1280px;
    margin: auto;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    .organization-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-top: 15px;
      margin-bottom: auto;
    }

    .logo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 55px;
      width: 55px;
      margin-right: 15px;
    }

    .footer {
      margin: auto 0 0;
      width: 100%;
    }
  }

  .chat-img {
    width: 70px;
    @include media-breakpoint-down(lg) {
      width: 50px;
    }

    position: absolute;

    &--1 {
      animation: typing-chat-img-animation infinite ease-in-out 1s alternate;

      top: 12vh;
      left: 14vw;
      @include media-breakpoint-down(lg) {
        top: 20vh;
        left: 5vw;
      }
    }

    &--2 {
      animation: typing-chat-img-animation-2 infinite ease-in-out 1.5s alternate;

      top: 17vh;
      left: 25vw;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &--3 {
      animation: typing-chat-img-animation infinite ease-in-out 1.2s alternate;

      top: 5vh;
      left: unset;
      right: 20vw;
      @include media-breakpoint-down(lg) {
        top: 10vh;
        right: 3vw;
      }
    }

    &--1, &--2, &--3 {
      animation-play-state: paused;
      &.animating {
        animation-play-state: running;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .card {
      box-shadow: none;
    }
  }
}

.chat-application--author {
  margin-top: auto;

  .chat-app-window {
    width: 700px;
    max-width: 55vw;
    margin: auto;

    @include media-breakpoint-down(sm) {
      max-width: calc(100vw - 3rem);
    }

    .user-chats {
      max-height: 55vh;
      background-color: #e6eaf5;

      @include media-breakpoint-down(sm) {
        max-height: unset;
      }
    }
  }
}

@keyframes typing-chat-img-animation {
  50% {
    transform: translate3d(0, 10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes typing-chat-img-animation-2 {
  50% {
    transform: translate3d(0, 12px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}