.exam--image-type {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 100%;
  padding: 0 2rem;
  margin-bottom: auto;

  .icon-answer {
    margin: 0 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
    > img {
      width: 70px;
      height: auto;
      border-radius: 15px;
    }
  }

  .selected-icon-answer {
    max-width: 70px;
    transition: all .1s ease-in;
    animation: tilt-shaking 0.25s cubic-bezier(.36,.07,.19,.97) both;
    pointer-events: none;
    position: relative;
    transform: translateY(20px);

    .selected-mark {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      bottom: -20px;
      display: flex;
      left: 50%;
      transform: translateX(-50%);
      background-color: red;
    }
  }
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(8deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-8deg); }
  100% { transform: rotate(0deg) translateY(-20px); }
}