// Apex chart Scss

.apexcharts-canvas{
  // Tooltip Color
  .apexcharts-tooltip{
    color: $body-color !important;
    &.dark{
      color: $white !important;
    }
  }
  // Toolbar Download Option Color
  .apexcharts-toolbar{
    .apexcharts-menu{
      .apexcharts-menu-item{
        color: $body-color;
      }
    }
  }
}

// apex charts tooltip
.apexcharts-xaxistooltip{
  color: $body-color !important;
}

// client-retention Chart - legend position for left
#client-retention-chart{
  .apexcharts-canvas{
    .apexcharts-legend{
      left: -14px !important;
    }
  }
}

// custom apexchart tooltip
.custom-apexchart-tooltip {
  padding: 16px;
  color: $body-color;
  font-size: 12px;

  & span {
    display: block;
    margin-bottom: 6px;

    &:first-child {
      padding-bottom: 6px;
      border-bottom: 1px solid $gray-500;
      margin-bottom: 12px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}