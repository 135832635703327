html body {
  .bg-full-screen-image--send-feedback {
    background: url("../../img/pages/send-feedback.svg") no-repeat bottom center, #e6eaf5;
    background-size: cover;

    @media (max-aspect-ratio: 2880/1800) {
      background-size: contain;
    }

    & .main {
      margin-top: 0 !important;
    }
  }
}

.send-feedback-page {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 1120px;
    max-width: 100vw;

    .organization-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-top: 15px;
      margin-bottom: auto;
    }

    .logo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 55px;
      width: 55px;
      margin-right: 15px;
    }
  }

  .send-feedback-card {
    width: 550px;
    max-width: 100%;
    margin: 50px auto 0;
    max-height: 80vh;
  }

  .send-feedback-card-body {
    overflow-y: auto;
    padding-bottom: 0;
  }

  .form-wrapper {
    width: 500px;
    max-width: 100%;
    margin: auto;

    .form-control { // textarea
      padding: 6px 10px;
      line-height: 22px;
    }
  }

  .switch-transition-enter {
    opacity: 0;
  }
  .switch-transition-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
  }
  .switch-transition-exit{
    opacity: 1;
  }
  .switch-transition-exit-active{
    opacity: 0;
    transition: opacity 400ms ease-in;
  }
}