/*!***** Vuexy Wizard *****!*/

// @import '../bootstrap-extended.scss'; // Bootstrap includes
// @import '../components.scss'; // Components includes

$header-padding-y: 1.5rem;
$header-padding-x: 1.5rem;
$content-padding-x: 1.5rem;
$content-padding-y: 1.5rem;
$trigger-padding-vertical: 1rem;
$step-box-height: 38px;
$step-box-width: 38px;

.bs-stepper {
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  width: 900px;
  min-height: 340px;
  display: flex;
  flex-direction: column;

  .bs-stepper-header {
    padding: $header-padding-y $header-padding-x;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba($black, 0.08);
    margin: 0;
    .line {
      flex: 0;
      min-width: auto;
      min-height: auto;
      background-color: transparent;
      margin: 0;
      padding: 0 1.75rem;
      color: $body-color;
      font-size: 1.5rem;
    }
    .step {
      margin-bottom: .25rem;
      margin-top: .25rem;
      .step-trigger {
        cursor:default !important;
        padding: 0;
        flex-wrap: nowrap;
        font-weight: normal;

        .bs-stepper-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: $step-box-width;
          height: $step-box-height;
          padding: 0.5em 0;
          font-weight: 500;
          color: $gray-100;
          background-color: rgba($gray-100, 0.12);
          border-radius: 0.35rem;
        }

        .bs-stepper-label {
          text-align: left;
          margin: 0;
          margin-top: 0.5rem;
          margin-left: 1rem;

          .bs-stepper-title {
            display: inherit;
            color: $body-color;
            font-weight: 600;
            line-height: 1rem;
            margin-bottom: 0rem;
          }

          .bs-stepper-subtitle {
            font-weight: 400;
            font-size: 0.85rem;
            color: $text-muted;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }

      &.active {
        .step-trigger {
          .bs-stepper-box {
            background-color: $primary;
            color: $white;
            box-shadow: 0 3px 6px 0 rgba($primary, 0.4);
          }
          .bs-stepper-label {
            .bs-stepper-title {
              color: $primary;
            }
          }
        }
      }

      &.crossed {
        .step-trigger {
          .bs-stepper-box {
            background-color: rgba($color: $primary, $alpha: 0.12);
            color: $primary !important;
          }
          .bs-stepper-label {
            .bs-stepper-title {
              color: $text-muted;
            }
          }
        }
        & + .line {
          color: $primary;
        }
      }
    }
  }
  .bs-stepper-content {
    flex: 1;
    padding: $content-padding-y $content-padding-x;
    .content {
      margin-left: 0;

      .content-header {
        margin-bottom: 1rem;
      }

      .trophy-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 0;
        text-align: center;
      }
    }
  }

  &.vertical {
    .bs-stepper-header {
      border-right: 1px solid $border-color;
      border-bottom: none;
      .step {
        .step-trigger {
          padding: $trigger-padding-vertical 0;
        }
      }
      .line {
        display: none;
      }
    }
    .bs-stepper-content {
      width: 100%;
      padding-top: 2.5rem;
      .content {
        &:not(.active) {
          display: none;
        }
      }
    }

    &.wizard-icons {
      .step {
        text-align: center;
      }
    }
  }

  &.wizard-modern {
    background-color: transparent;
    box-shadow: none;
    .bs-stepper-header {
      border: none;
    }
    .bs-stepper-content {
      background-color: $white;
      border-radius: 0.5rem;
      box-shadow: $box-shadow;
    }
  }

  .stepper-block, .stepper-block-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.horizontal-wizard,
.vertical-wizard,
.modern-horizontal-wizard,
.modern-vertical-wizard {
  margin-bottom: 2.2rem;
}

// RTl
html[data-textdirection='rtl'] {
  .btn-prev,
  .btn-next {
    display: flex;
    i,
    svg {
      transform: rotate(-180deg);
    }
  }
}

// Media Queries
@media (max-width: 992px) {
  .bs-stepper {
    .bs-stepper-header {
      flex-direction: column;
      align-items: flex-start;
      .step {
        .step-trigger {
          padding: 0.5rem 0 !important;
          flex-direction: row;
        }
      }
      .line {
        display: none;
      }
    }
    &.vertical {
      flex-direction: column;
      .bs-stepper-header {
        align-items: flex-start;
      }
      .bs-stepper-content {
        padding-top: 1.5rem;
      }
    }
  }
}


/*
!***** Vuexy Wizard *****!

.vx-wizard {
  box-shadow: none !important;
  display: table;
  width: 100%;
  &.nav.nav-tabs {
    .step-wrapper.nav-item {
      display: table-cell;
      width: auto;
      .step.nav-link {
        transform: translateY(0) !important;
        text-align: center !important;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .step-text {
          border-radius: 50%;
          font-size: 1.5rem;
          z-index: 2;
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          background: $white;
        }
        &.active {
          .step-text {
            background-color: $primary;
            color: $white;
          }
          &:after {
            background: $primary !important;
            box-shadow: none !important;
            height: 3px;
            top: 50% !important;
            z-index: -1;
            width: 50%;
          }
        }
        &.done {
          .step-text {
            border-color: $primary !important;
          }
          &:before,
          &:after {
            background: $primary !important;
            box-shadow: none !important;
            height: 3px;
            top: 50% !important;
            z-index: -1;
            width: 50%;
            content: "";
            position: absolute;
            top: 50%;
            width: 45.5%;
          }
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
        &:not(.active) {
          .step-text {
            border: 3px solid $gray-600;
            color: $gray-600;
          }
        }
      }
    }
  }
}
*/
