.exam-questions-preview {
  .question-answers-wrapper {
    display: flex;
    justify-content: center;

    &__labels {
      @include media-breakpoint-down(xs) {
        justify-content: space-around;
      }
    }
  }

  .answer-item {
    &__value {
      border: 1px solid #666;
      border-radius: 14px;
      width: 28px;
      height: 28px;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 3px;

      @include media-breakpoint-down(xs) {
        margin: 0 1px;
      }
    }

    &__label {
      min-width: 30px;

      @include media-breakpoint-down(xs) {
        min-width: 3px;
      }
    }
  }
}