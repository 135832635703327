$exam-bcg-color: #e6eaf5;
$slide-width: 60vw;
$slider-wrapper-x-margin: 60px;

$max-container-width: 1280px;

.exam-page {
  background: url("../../img/pages/exam.svg") no-repeat bottom center, $exam-bcg-color;
  background-size: cover;
  height: 100vh;

  @media (max-aspect-ratio: 2880/1800) {
    background-size: contain;
  }

  @include media-breakpoint-down(sm) {
    background: $exam-bcg-color;
  }

  .container {
    max-width: $max-container-width;
    margin: auto;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    .organization-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      width: calc(#{$slide-width} + 2 * #{$slider-wrapper-x-margin});
      max-width: 920px;
      margin-top: 15px;
      margin-bottom: auto;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin: 10px;
      }
    }

    .logo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 55px;
      width: 55px;
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        height: 30px;
        width: 30px;
      }
    }

    .brand-text {
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }
    }

    .footer {
      margin: auto 0 0;
      width: 100%;

      @include media-breakpoint-down(sm) {
        margin-top: 0;
        padding: 0 10px 10px;
      }
    }
  }

  .exam {
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex: 1;
    }
  }

  .exam-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;

    @include media-breakpoint-down(sm) {
      height: 100%;
    }
  }

  .exam-page-card {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 70%;
      max-width: $max-container-width;
    }
  }

  .exam-card {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      background: transparent;
      height: 100%;
      width: 100%;
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  .question-number-buttons-wrapper {
    transition: transform .4s;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 30px;
      left: 0;
      height: calc(100% - 60px);
      width: 75px;

      padding: 10px 22px;
      border-right: 3px solid $gray-800;

      transition: transform .4s;

      &--hidden {
        transform: translate3d(-80px, 0, 0);
      }
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - 20px);

      &--hidden {
        transform: translate3d(0, 100px, 0);
      }
    }
  }

  .question-number-buttons {
    display: flex;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    @include media-breakpoint-down(sm) {
      border-top: 1px solid $gray-800;
      border-bottom: 1px solid $gray-800;
      overflow: auto;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .question-number-buttons-title {
    text-transform: uppercase;
    font-size: .9rem;
  }

  .question-number-btn {
    display: block;
    position: relative;

    background-color: transparent;
    border: none;
    font-size: 1.7rem;
    padding: 8px 15px;

    outline: none;
    transition: opacity 0s .4s;

    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
      padding: 10px;
    }

    &__arrow {
      position: absolute;
      top: 25%;
      left: -18px;

      pointer-events: none;
      transition: transform .3s ease-in-out;
      transform: translate3d(-100%, 0, 0);

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &:hover .arrow, &:focus .arrow {
      transform: translate3d(0, 0, 0);
    }

    &--selected {
      pointer-events: none;
      font-weight: bold;

      & .arrow {
        transform: translate3d(0, 0, 0);
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

  .slider-wrapper {
    overflow: hidden;
    margin: 30px $slider-wrapper-x-margin;
    height: 50vh;

    @include media-breakpoint-down(sm) {
      // height = 100vh - header - navButtons - footer
      height: calc(100vh - 55px - 60px - 53px);
      width: 100%;
      margin: 0;
    }
  }

  .slide {
    height: 100%;
    width: $slide-width;
    max-width: 800px;

    margin: auto;
    padding: 30px;

    transition: transform .35s ease-out;

    @include media-breakpoint-down(sm) {
      padding: 20px 0;
      overflow: scroll;
      width: calc(100vw - 80px);
    }
  }

  .slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .question-number-decoration-wrapper {
    transition: opacity .2s ease-in-out .24s;

    .question-number-decoration {
      position: absolute;
      top: 0;
      left: 110px;

      pointer-events: none;
      font-size: 10.5rem;
      opacity: 0.2;

      transition: transform .7s ease-in-out .2s;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .title {
    margin: auto 30px 60px;
    line-height: 1.6em;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
      line-height: 1.3em;
      margin: .1rem 0 4rem;
    }
  }

  .feedback-wrapper {
    margin-bottom: 70px;
  }

  .descriptions-wrapper {
    position: absolute;
    width: 120%; // override in inline style depends on label length
    top: -30px;
    left: -10%; // override in inline style depends on label length
    display: flex;
    justify-content: space-between;

    font-size: 1rem;
    letter-spacing: -0.05px;

    @include media-breakpoint-down(sm) {
      height: calc(100% + 60px);
      width: 100%;
      left: 0;
      flex-direction: column-reverse;
      align-content: space-between;
      text-align: center;
      z-index: -1;
    }

    &__text {
      user-select: none; // for better slider-presentation-type usage
    }
  }

  .send-feedback-wrapper {
    display: flex;
    margin: 20px auto;

    &--border {
      border-radius: 0;
      border: 1px solid $gray-700;
      box-shadow:  5px 15px rgba($warning, 0.5),
       -13px 8px rgba($primary, 0.5),
       -8px -12px rgba($success, 0.5),
       13px -6px rgba($secondary, 0.5);
    }

    &--question-page {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.exam {
  .question-with-description-wrapper {
    position: relative;
    margin-bottom: auto;

    max-width: 100%;
    padding: 0 2rem;
  }

  /* question-numeric-type */
  .switch {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding: 0 1.2rem;
      width: 100%;

      flex-direction: column-reverse;
    }

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 3rem;
      border: 2px solid $gray-400;
      border-radius: 30px;

      @include media-breakpoint-down(sm) {
        width: 3rem;
        height: 100%;
        left: calc(50% - 1.5rem);
      }
    }
    &__label {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 2rem;
      padding: 1rem 2rem;
      text-align: center;
      cursor: pointer;
      transition: transform .45s cubic-bezier(.49,1.85,.64,1);
      transform-origin: center;

      @include media-breakpoint-down(sm) {
        width: 1.5rem;
        padding: .7rem 1.5rem;
      }
    }

    &__indicator {
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: -.4rem;
      left: .1rem;
      border-radius: 50%;
      opacity: 0.5;
      transition: transform .7s cubic-bezier(.02,.94,.09,.97),
      background .6s cubic-bezier(.17,.67,.14,1.03);
      transform: translate3d(1rem,0,0);
      pointer-events: none;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .switch__label--selected {
      font-weight: bold;
      transform: scale3d(1.5, 1.5, 1.5);

      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: rgba(150, 150, 150, .25);

        @include media-breakpoint-down(sm) {
          width: 2.2rem;
          height: 2.2rem;
        }
      }
    }

    .input[type="radio"] {
      &:not(:checked),
      &:checked {
        display: none;
      }
    }
  }
  /* e/o question-numeric-type */

  /* question-slider-type */
  .question-slider-type {
    min-width: 360px;
    display: flex;
    flex-direction: column;

    .labels-wrapper {
      margin-top: 16px;

      width: calc(100% + 16px);
      margin-left: -8px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      color: $text;
    }

    .label {
      width: 16px;
      font-size: 12px;
      user-select: none;
      text-align: center;

      transition: transform .45s cubic-bezier(.49,1.85,.64,1);
      transform-origin: center;

      margin: 0 26px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &--selected {
        font-weight: bold;
        transform: scale3d(1.5, 1.5, 1.5);
      }
    }
  }
  /* e/o question-slider-type */
}
